import React from 'react';

const CodingWithUniform = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 960 138">
      <path
        fill="#E6F2FF"
        fillOpacity=".8"
        fillRule="evenodd"
        d="M114.53,63.29 C114.53,48.52 125.8,38.16 139.73,38.16 C150.44,38.16 159.75,44.04 163.18,54.54 L148.9,54.54
        C146.87,50.62 143.93,49.15 139.73,49.15 C131.82,49.15 126.71,54.68 126.71,63.29 C126.71,71.83 131.82,77.43
        139.73,77.43 C143.93,77.43 146.87,75.96 148.9,72.04 L163.18,72.04 C159.75,82.54 150.44,88.42 139.73,88.42
        C125.8,88.42 114.53,78.06 114.53,63.29 Z M166.33,68.4 C166.33,56.08 175.5,48.52 186.77,48.52 C197.97,48.52
        207.14,56.08 207.14,68.4 C207.14,80.72 198.11,88.49 186.84,88.49 C175.57,88.49 166.33,80.72 166.33,68.4 Z
        M194.96,68.47 C194.96,61.68 190.76,58.74 186.77,58.74 C182.64,58.74 178.51,61.68 178.51,68.47 C178.51,75.26
        182.78,78.27 186.84,78.27 C190.9,78.27 194.96,75.26 194.96,68.47 Z M210.22,68.54 C210.22,56.64 218.27,48.52
        228,48.52 C233.11,48.52 236.96,50.55 239.48,54.05 L239.48,36.2 L251.45,36.2 L251.45,88 L239.48,88 L239.48,82.33
        C236.82,86.11 233.11,88.49 228,88.49 C218.27,88.49 210.22,80.44 210.22,68.54 Z M239.48,68.47 C239.48,61.89
        235,58.88 230.94,58.88 C226.88,58.88 222.4,61.96 222.4,68.54 C222.4,75.12 226.88,78.13 230.94,78.13 C235,78.13
        239.48,75.05 239.48,68.47 Z M260.2,88 L260.2,48.94 L272.17,48.94 L272.17,88 L260.2,88 Z M266.15,46.56
        C262.23,46.56 259.15,43.48 259.15,39.56 C259.15,35.57 262.23,32.49 266.15,32.49 C270.07,32.49 273.15,35.57
        273.15,39.56 C273.15,43.48 270.07,46.56 266.15,46.56 Z M292.89,48.94 L292.89,54.68 C295.13,50.97 299.26,48.52
        304.72,48.52 C313.47,48.52 319.77,54.68 319.77,65.18 L319.77,88 L307.87,88 L307.87,66.79 C307.87,61.68
        304.93,58.88 300.38,58.88 C295.83,58.88 292.89,61.68 292.89,66.79 L292.89,88 L280.92,88 L280.92,48.94
        L292.89,48.94 Z M354.56,68.47 C354.56,61.89 350.08,58.88 346.02,58.88 C341.96,58.88 337.48,61.96 337.48,68.54
        C337.48,75.12 341.96,78.13 346.02,78.13 C350.08,78.13 354.56,75.05 354.56,68.47 Z M325.3,68.54 C325.3,56.64
        333.35,48.52 343.08,48.52 C348.19,48.52 352.04,50.55 354.56,54.05 L354.56,48.94 L366.53,48.94 L366.53,87.79
        C366.53,98.29 361.07,107.32 346.93,107.32 C335.31,107.32 327.75,102.77 326.77,92.55 L338.53,92.55 C339.72,95.63
        342.66,96.96 346.93,96.96 C350.92,96.96 354.56,94.58 354.56,87.79 L354.56,82.33 C351.9,86.11 348.19,88.49
        343.08,88.49 C333.35,88.49 325.3,80.44 325.3,68.54 Z M438.42,48.94 L449.69,48.94 L438,88 L425.12,88
        L419.66,64.9 L414.13,88 L401.25,88 L389.56,48.94 L401.53,48.94 L407.62,76.38 L413.64,48.94 L426.31,48.94
        L432.33,76.38 L438.42,48.94 Z M453.4,88 L453.4,48.94 L465.37,48.94 L465.37,88 L453.4,88 Z M459.35,46.56
        C455.43,46.56 452.35,43.48 452.35,39.56 C452.35,35.57 455.43,32.49 459.35,32.49 C463.27,32.49 466.35,35.57
        466.35,39.56 C466.35,43.48 463.27,46.56 459.35,46.56 Z M490.71,77.85 L494.98,77.85 L494.98,88 L488.89,88
        C480.77,88 475.1,85.06 475.1,75.12 L475.1,58.88 L471.46,58.88 L471.46,48.94 L475.1,48.94 L475.1,39.42
        L487.07,39.42 L487.07,48.94 L494.91,48.94 L494.91,58.88 L487.07,58.88 L487.07,75.12 C487.07,76.94 488.05,77.85
        490.71,77.85 Z M513.39,36.2 L513.39,54.68 C515.63,50.97 519.76,48.52 525.22,48.52 C533.97,48.52 540.27,54.68
        540.27,65.18 L540.27,88 L528.37,88 L528.37,66.79 C528.37,61.68 525.43,58.88 520.88,58.88 C516.33,58.88
        513.39,61.68 513.39,66.79 L513.39,88 L501.42,88 L501.42,36.2 L513.39,36.2 Z M567.71,68.19 L567.71,38.86
        L579.68,38.86 L579.68,68.19 C579.68,74 582.55,77.36 588.22,77.36 C593.89,77.36 596.9,74 596.9,68.19
        L596.9,38.86 L608.87,38.86 L608.87,68.19 C608.87,81.77 598.58,88.49 588.01,88.49 C577.37,88.49 567.71,81.77
        567.71,68.19 Z M629.31,48.94 L629.31,54.68 C631.55,50.97 635.68,48.52 641.14,48.52 C649.89,48.52 656.19,54.68
        656.19,65.18 L656.19,88 L644.29,88 L644.29,66.79 C644.29,61.68 641.35,58.88 636.8,58.88 C632.25,58.88
        629.31,61.68 629.31,66.79 L629.31,88 L617.34,88 L617.34,48.94 L629.31,48.94 Z M664.59,88 L664.59,48.94
        L676.56,48.94 L676.56,88 L664.59,88 Z M670.54,46.56 C666.62,46.56 663.54,43.48 663.54,39.56 C663.54,35.57
        666.62,32.49 670.54,32.49 C674.46,32.49 677.54,35.57 677.54,39.56 C677.54,43.48 674.46,46.56 670.54,46.56 Z
        M702.39,48.94 L702.39,58.88 L697.42,58.88 L697.42,88 L685.45,88 L685.45,58.88 L681.81,58.88 L681.81,48.94
        L685.45,48.94 L685.45,47.47 C685.45,37.53 690.63,33.19 701.83,33.19 L701.83,43.34 C698.68,43.34 697.42,44.32
        697.42,47.47 L697.42,48.94 L702.39,48.94 Z M704.28,68.4 C704.28,56.08 713.45,48.52 724.72,48.52 C735.92,48.52
        745.09,56.08 745.09,68.4 C745.09,80.72 736.06,88.49 724.79,88.49 C713.52,88.49 704.28,80.72 704.28,68.4 Z
        M732.91,68.47 C732.91,61.68 728.71,58.74 724.72,58.74 C720.59,58.74 716.46,61.68 716.46,68.47 C716.46,75.26
        720.73,78.27 724.79,78.27 C728.85,78.27 732.91,75.26 732.91,68.47 Z M763.01,48.94 L763.01,56.22 C765.53,51.6
        769.38,48.52 774.21,48.52 L774.21,60.7 L771.27,60.7 C766.02,60.7 763.01,62.73 763.01,69.52 L763.01,88
        L751.04,88 L751.04,48.94 L763.01,48.94 Z M790.94,48.94 L790.94,54.68 C793.18,50.97 797.31,48.52 802.77,48.52
        C808.58,48.52 813.27,51.25 815.79,56.08 C818.52,51.74 823.21,48.52 829.16,48.52 C838.61,48.52 844.7,54.68
        844.7,65.18 L844.7,88 L832.8,88 L832.8,66.79 C832.8,61.68 829.86,58.88 825.31,58.88 C820.76,58.88 817.82,61.68
        817.82,66.79 L817.82,88 L805.92,88 L805.92,66.79 C805.92,61.68 802.98,58.88 798.43,58.88 C793.88,58.88
        790.94,61.68 790.94,66.79 L790.94,88 L778.97,88 L778.97,48.94 L790.94,48.94 Z"
      />
    </svg>
  );
};

export default CodingWithUniform;
